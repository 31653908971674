import './App.css';
import "./node_modules/bootstrap/dist/css/bootstrap.min.css";
import Header from './comps/header';
import MainCards from './comps/mainCards';
import testImg from './images/litleAcheron.webp'
import poolImg from './images/pool_1.jpg'
import barImg from './images/restaurant_2.jpg'
import generalImg from './images/general_1.jpg'
import eventsImg from './images/events_1.jpg'
function App() {

  const descriptions = ['General Information','Restaurants & Bars','Events & Activities','Explore the Area','Pool']
  const links = ['/generalInfo','/restaurants-bars','/events-activities','/explore-area','/pool']

  return (
    <div className="App">
      <Header/>
      <div className='d-flex flex-column col-12 mb-2  px-3'>
        <div className='col-12 d-flex flex-column flex-lg-row gap-lg-1 '>
          <MainCards myText={descriptions[0]} myImg={generalImg} navLink={links[0]} />
          <hr></hr>
          <MainCards myText={descriptions[1]} myImg={barImg} navLink={links[1]}/>
        </div>
        <hr></hr>
        <div className='col-12 d-flex flex-column flex-lg-row gap-lg-1'>
        <MainCards myText={descriptions[2]} myImg={eventsImg} navLink={links[2]}/>
        <hr></hr>
        <MainCards myText={descriptions[3]} myImg={testImg} navLink={links[3]}/>
        </div>
        <hr></hr>
        <div className='col-12 d-flex flex-column flex-lg-row '>
        <MainCards myText={descriptions[4]} myImg={poolImg} navLink={links[4]}/>
        </div>
      </div>
    </div>
  );
}

export default App;
