import React, { useState } from 'react';
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./header.css";
import logo from './../images/logo_ninosgrand-150x150.png';

const Header = () => {
  const [isHomePage, setIsHomePage] = useState(window.location.pathname === '/'); // Check initial state

  const handleNavigateHome = () => {
    setIsHomePage(true); // Update state for visual feedback (optional)
    window.location.href = '/'; // Navigate to main page
  };

  return (
    <div className='col-12 d-flex flex-row headerDiv pb-1 px-1'>
      <img
        className='col-2'
        src={logo}
        alt="Ninos Grand Beach Resort"
        onClick={handleNavigateHome} // Attach onClick handler
      />
      <h2 className='col-10' onClick={handleNavigateHome}>Ninos Grand Beach Resort</h2>
    </div>
  );
};

export default Header;

