import React from 'react';
import Header from './header.js'
import { useEffect } from 'react';
//import css
import './restaurants.css'
//import images
import teaImg from './../images/icons/breakfast.svg'
import dinnerImg from './../images/icons/diner.svg'
import lunchImg from './../images/icons/luntch.svg'
import pizzaImg from './../images/icons/pizza.svg'
import sunImg from './../images/icons/sun.svg'
import timeImg from './../images/icons/time.svg'
const Restaurants = () => {

        useEffect(() => {
            window.scrollTo(0, 0); // Scroll to top on component mount
        }, []);

    return (
        <div className='col-12 d-flex flex-column'>
            <Header></Header>
            <div className='col-12 d-flex flex-column px-2 py-3 gap-3 epirus'>
                <h2 className='sectionTitles'>Epirus Restaurant</h2>
                <div className='d-flex flex-row mx-1 mt-2 infoHolder'>
                    <div className='col-2 d-flex justify-content-center'>
                        <img src={teaImg} className='iconsGeneral'></img>
                    </div>
                    <div className='col-5 d-flex flex-column justify-content-center'>
                        <span>Breakfast time:</span>
                        <p>07:30 – 10:00</p>
                    </div>
                </div>
                <div className='d-flex flex-row mx-1 infoHolder'>
                    <div className='col-2 d-flex justify-content-center'>
                        <img src={lunchImg} className='iconsGeneral'></img>
                    </div>
                    <div className='col-5 d-flex flex-column justify-content-center'>
                        <span>Lunch time:</span>
                        <p>12:30 – 14:30</p>
                    </div>
                </div>
                <div className='d-flex flex-row mx-1 infoHolder'>
                    <div className='col-2 d-flex justify-content-center'>
                        <img src={dinnerImg} className='iconsGeneral'></img>
                    </div>
                    <div className='col-5 d-flex flex-column justify-content-center'>
                        <span>Dinner time:</span>
                        <p>19:00 – 21:30</p>
                    </div>
                </div>
                <div className='col-12 d-flex flex-column'>
                    <span className='allInclText'>*Part of the all inclusive</span>
                    <hr></hr>
                </div>
                <p>
                    Unwind at Epirus Restaurant, our all-inclusive buffet. 
                    Savour in Greek and Mediterranean flavours, local 
                    specialties, and our live cooking station. Perfect for 
                    every palate, with kid-friendly options
                </p>
            </div>
            {/*#################  Epirus bar section ####################*/}
            <div className='col-12 d-flex flex-column px-2 py-3 gap-3 epirus'>
                <h2 className='sectionTitles'>Epirus Bar</h2>
                <div className='d-flex flex-row mx-1 mt-2 infoHolder'>
                    <div className='col-2 d-flex justify-content-center'>
                        <img src={teaImg} className='iconsGeneral'></img>
                    </div>
                    <div className='col-5 d-flex flex-column justify-content-center'>
                        <span>Breakfast time:</span>
                        <p>07:30 – 10:00</p>
                    </div>
                </div>
                <div className='d-flex flex-row mx-1 infoHolder'>
                    <div className='col-2 d-flex justify-content-center'>
                        <img src={lunchImg} className='iconsGeneral'></img>
                    </div>
                    <div className='col-5 d-flex flex-column justify-content-center'>
                        <span>Lunch time:</span>
                        <p>12:30 – 14:30</p>
                    </div>
                </div>
                <div className='d-flex flex-row mx-1 infoHolder'>
                    <div className='col-2 d-flex justify-content-center'>
                        <img src={dinnerImg} className='iconsGeneral'></img>
                    </div>
                    <div className='col-5 d-flex flex-column justify-content-center'>
                        <span>Dinner time:</span>
                        <p>19:00 – 23:00</p>
                    </div>
                </div>
                <div className='col-12 d-flex flex-column'>
                    <span className='allInclText'>*Part of the all inclusive</span>
                    <hr></hr>
                </div>
                <p>
                    Located by the Epirus Restaurant. Enjoy international 
                    drinks, local favourites (like Ouzo!), wines & more. 
                    Open during all meals.
                </p>
            </div>
            {/*##################### Olympia Bar section #######################*/}
            <div className='col-12 d-flex flex-column px-2 py-3 gap-3 olympia'>
                <h2 className='sectionTitles'>Olympia Bar</h2>
                <div className='d-flex flex-column col-12 gap-3'>
                    <div className='d-flex flex-row gap-2 col-12 justify-content-evenly'>
                        <div className='col-5 d-flex flex-row infoHolder'>
                            <div className='d-flex justify-content-center'>
                                <img src={timeImg} className='iconsGeneral'></img>
                            </div>
                            <div className='d-flex flex-column justify-content-center'>
                                <span>Opening Hours:</span>
                                <p>10:15 - 19:00</p>
                            </div>
                        </div>
                        <div className='col-5 d-flex flex-row infoHolder'>
                            <div className='d-flex justify-content-center'>
                                <img src={pizzaImg} className='iconsGeneral'></img>
                            </div>
                            <div className='d-flex flex-column justify-content-center'>
                                <span>Pizza Corner:</span>
                                <p>11:00 – 17:00</p>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-row gap-2 col-12 justify-content-evenly'>
                        <div className=' d-flex flex-row infoHolder'>
                            <div className='d-flex justify-content-center'>
                                <img src={sunImg} className='iconsGeneral'></img>
                            </div>
                            <div className='d-flex flex-column justify-content-center'>
                                <span>Morning Snacks:</span>
                                <p>11:00 – 12:30</p>
                            </div>
                        </div>
                        <div className='col-5 d-flex flex-row infoHolder'>
                            <div className='d-flex justify-content-center'>
                                <img src={teaImg} className='iconsGeneral'></img>
                            </div>
                            <div className='d-flex flex-column justify-content-center'>
                                <span>Afternoon Tea:</span>
                                <p>15:00 – 18:00</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 d-flex flex-column'>
                    <span className='allInclText'>*Part of the all inclusive</span>
                    <hr></hr>
                </div>
                <p>
                Cool off with local & international drinks, cocktails & 
                pizzas at Olympia Pool Bar, located next to the main 
                pool. Grab a mini sandwich or sweet treat too!
                </p>
            </div>
            {/*#################  Beach Bar section ####################*/}
            <div className='col-12 d-flex flex-column px-2 py-3 gap-3 beachBar'>
                <h2 className='sectionTitles'>Beach Bar</h2>
                <div className='d-flex flex-row mx-1 infoHolder'>
                    <div className='col-2 d-flex justify-content-center'>
                        <img src={timeImg} className='iconsGeneral'></img>
                    </div>
                    <div className='col-5 d-flex flex-column justify-content-center'>
                        <span>Opening Hours:</span>
                        <p>10:00 - 00:00</p>
                    </div>
                </div>
                <div className='col-12 d-flex flex-column'>
                    <hr></hr>
                </div>
                <p>
                Indulge by the sea! Our Beach Bar offers premium 
                cocktails, spirits, wines & fresh juices (à la carte). 
                Relax with a classic or creative drink & witness 
                breathtaking sunsets.
                </p>
            </div>
            {/*#################  A la carte restaurant section ####################*/}
            <div className='col-12 d-flex flex-column px-2 py-3 gap-3 beachBar'>
                <h2 className='sectionTitles'>A la carte restaurant</h2>
                <div className='d-flex flex-row mx-1 infoHolder'>
                    <div className='col-2 d-flex justify-content-center'>
                        <img src={timeImg} className='iconsGeneral'></img>
                    </div>
                    <div className='col-5 d-flex flex-column justify-content-center'>
                        <span>Opening Hours:</span>
                        <p>19:00 - 21:30</p>
                    </div>
                </div>
                <div className='col-12 d-flex flex-column'>
                    <hr></hr>
                </div>
                <p>
                The a la carte restaurant invites you to experience the 
                delicious Greek food. Our menu features a delightful 
                selection of traditional and modern Greek dishes, 
                prepared with fresh, locally sourced ingredients. 
                Please make your reservation at the reception one 
                day in advance
                </p>
            </div>
            {/*#################  Nikopolis Bar section ####################*/}
            <div className='col-12 d-flex flex-column px-2 py-3 gap-3 beachBar'>
                <h2 className='sectionTitles'>Nikopolis Bar</h2>
                <div className='d-flex flex-row mx-1 infoHolder'>
                    <div className='col-2 d-flex justify-content-center'>
                        <img src={timeImg} className='iconsGeneral'></img>
                    </div>
                    <div className='col-5 d-flex flex-column justify-content-center'>
                        <span>Opening Hours:</span>
                        <p>19:00 - 00:00</p>
                    </div>
                </div>
                <div className='col-12 d-flex flex-column'>
                    <hr></hr>
                </div>
                <p>
                Nikopolis is an a la cart, main bar (with premium 
                brand drinks / coffee / beverages on extra charge). 
                Located in the main building next to the Lobby, the 
                Nikopolis Main bar is the spot to sip a classic or a 
                twisted cocktail, premium brand spirits, wines, 
                freshly made juices, soft drinks, cold & warm 
                beverages.
                </p>
            </div>
            {/*#################  Mondo Cafe section ####################*/}
            <div className='col-12 d-flex flex-column px-2 py-3 gap-3'>
                <h2 className='sectionTitles'>Mondo Cafe</h2>
                <div className='d-flex flex-row mx-1 infoHolder'>
                    <div className='col-2 d-flex justify-content-center'>
                        <img src={timeImg} className='iconsGeneral'></img>
                    </div>
                    <div className='col-5 d-flex flex-column justify-content-center'>
                        <span>Opening Hours:</span>
                        <p>12:00 - 18:00</p>
                    </div>
                </div>
                <div className='col-12 d-flex flex-column'>
                    <hr></hr>
                </div>
                <p>
                Mondo Café is your family escape in paradise! Expert 
                baristas whip up perfect lattes & specialty coffees. 
                Enjoy real Greek coffee, cappuccinos, fresh juices, 
                yoghurt, local pastries, and ice cream!
                </p>
            </div>
        </div>
    );
}

export default Restaurants;
