import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./mainCards.css"
const MainCards = ({ myText,myImg, navLink }) => { 
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`${navLink}`); // Replace '/new-page' with your desired path
    };


        return (
          
            <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center card ' onClick={handleClick}>
                <img src={myImg}></img>
                <h3>{myText}</h3>
            </div>
          
        );
    }


export default MainCards;
