import React from 'react';
import Header from './header.js'
import { useEffect } from 'react';
//import  css
import './genInfo.css'
//importing image
import wifiImg from './../images/icons/wifi.svg'
import checkImg from './../images/icons/check.svg'
import callImg from './../images/icons/call.svg'

const GenInfo = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div className='col-12 d-flex flex-column'>
            <Header></Header>
            <div className='col-12 px-2 py-3 d-flex flex-column gap-3'>
                <div><h2 className='sectionTitles'>General Information</h2></div>
                <div className=' d-flex flex-column gap-4 px-3'>
                    <div className='flex-row d-flex gap-2 infoHolder'>
                        <div className='col-1 d-flex justify-content-center'>
                            <img src={wifiImg} className='iconsGeneral'></img>
                        </div>
                        <div className='col-5 d-flex flex-column justify-content-center'>
                            <span>Wifi Name:</span>
                            <p>Ninos Grand Hotel</p>
                        </div>
                        <div className='col-5'>
                        <span>Wifi Password:</span>
                            <p>ninosgrand</p>
                        </div>
                    </div>
                    <div className='flex-row d-flex gap-2 infoHolder'>
                        <div className='col-1 d-flex justify-content-center'>
                            <img src={checkImg} className='iconsGeneral'></img>
                        </div>
                        <div className='col-5'>
                            <span>Check In:</span>
                            <p>15:00</p>
                        </div>
                        <div className='col-5'>
                            <span>Check Out:</span>
                            <p>11:00</p>
                        </div>
                    </div>
                    <div className='flex-row d-flex gap-2 infoHolder'>
                        <div className='col-1 d-flex justify-content-center'>
                            <img src={callImg} className='iconsGeneral'></img>
                        </div>
                        <div className='col-5'>
                            <span>Dial Reception:</span>
                            <p>9 (Room phone)</p>
                        </div>
                        <div className='col-5'>
                            <span>Dial Reception:</span>
                            <p>+30 26820 52659</p>
                        </div>
                    </div>
                </div>
                <div className=' d-flex flex-column px-2 mt-2 gap-1 pHolder'>
                    <p>There are luggage rooms by the reception to store your bags at arrival and departure. </p>
                    <p>The hotel does not take responsibility for valuables stored in the luggage room.</p>
                    <p>On floor -1 there are showers for the day of departure, no booking needed.</p>
                </div>
                <hr></hr>
            </div>
            <div className='px-2 py-2 d-flex flex-column col-12 massage'>
                <h2 className='sectionTitles'>Massage</h2>
                <p>Step into a haven of tranquillity at our on-site 
                    massage room. Whether you seek deep tissue work 
                    to ease muscle tension or a soothing Swedish 
                    massage to melt away stress, our massage room 
                    provides the perfect environment to rejuvenate your 
                    body and mind. Please book your appointment at 
                    the reception.
                </p>
                <a href='#'>See all treatments </a>
                <hr></hr>
            </div>
            <div className='px-2 py-2 d-flex flex-column col-12 gym'>
                <h2 className='sectionTitles'>Gym</h2>
                <p>Maintain your workout routine with our convenient 
                    on-site gym featuring cardio equipment and 
                    strength training options. The gym is located on 
                    floor -1, next to the Mondo cafe. 
                </p>
                <hr></hr>
            </div>
            <div className='px-2 py-2 d-flex flex-column col-12 workoutPlatform'>
                <h2 className='sectionTitles'>Workout Platform</h2>
                <p>Enjoy our workout platform by block 4, where you 
                    can feel the breeze as you move your body. Beyond 
                    daily morning yoga, our outdoor platform comes 
                    alive with a range of workout classes throughout the 
                    day with Apollo. Check out the workout schedule by 
                    the reception or in your Apollo welcome material.  
                </p>
                <hr></hr>
            </div>
        </div>
    );
}

export default GenInfo;
