import React from 'react';
import Header from './header.js'
import { useEffect } from 'react';
//import css
import './pool.css'
//import images
import eventsImg from './../images/events_1.jpg'
import timeImg from './../images/icons/time.svg'
import poolImg from './../images/pool_1.jpg'

const Pool = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);



    return (
        <div>
            <Header/>
            <div className='d-flex flex-column col-12 py-4'>
                <h3 className='sectionTitles mx-4 '>Pool</h3>
                <div className='imgHolder mb-4'>
                    <img src={poolImg} className='eventsImg'/>
                </div>
                <p className='myText'>Dive into endless fun at our refreshing pools! Perfect 
                for swimming, lounging poolside, or playing with 
                friends, our three pools offer something for everyone.<br/><br/>


                Please do not reserve sunbeds at the pool area by 
                leaving towels or personal belongings. All unattended 
                towels will be removed.
                </p>
                <div className='d-flex flex-row mx-1 mt-2 infoHolder mb-3'>
                    <div className='col-2 d-flex justify-content-center'>
                        <img src={timeImg} className='iconsGeneral'></img>
                    </div>
                    <div className='col-5 d-flex flex-column justify-content-center'>
                        <span>Opening Hours:</span>
                        <p>10:00 - 19:00</p>
                    </div>
                </div>
                <hr></hr>
            </div>
            <div className='d-flex flex-column col-12 py-4'>
                <h3 className='sectionTitles mx-4 '>Pool Towels</h3>
                <p className='myText'>Pool towels are available in the reception for a 
                deposit of 10€ per towel, paid in cash. To get your 
                deposit back, please return the towel and the towel 
                card.<br/><br/>


                You can change the pool towel for 2€ per towel by 
                the main pool daily between 10-10:30 and 
                18:00-18:30
                </p>
            </div>
        </div>
    );
}

export default Pool;
