import React from 'react';
import Header from './header.js'
import { useEffect } from 'react';
//import css
import './events.css'
//import images
import eventsImg from './../images/events_1.jpg'
import kidsImg from './../images/kids.jpg'

const Events = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <Header/>
            <h2 className='sectionTitles mx-3 mt-4'>Events</h2>
            <div className='d-flex flex-column col-12 py-4'>
                <h3 className='sectionTitles mx-4 '>Greek Night:</h3>
                <div className='imgHolder mb-4'>
                    <img src={eventsImg} className='eventsImg'/>
                </div>
                <p className='myText'>Enjoy live Greek music on Thursday!</p>
                <hr></hr>
            </div>
            <div className='d-flex flex-column col-12 py-4'>
                <h3 className='sectionTitles mx-4 '>Sunset By the Beach Bar:</h3>
                <div className='imgHolder mb-4'>
                    <img src={eventsImg} className='eventsImg'/>
                </div>
                <p className='myText'>Enjoy the most beautiful sunset in the area by the beach bar with premium cocktails and spirits.</p>
                <hr></hr>
            </div>
            <div className='d-flex flex-column col-12 py-4'>
                <h3 className='sectionTitles mx-4 '>Kids Club:</h3>
                <div className='imgHolder mb-4'>
                    <img src={kidsImg} className='eventsImg'/>
                </div>
                <p className='myText'>Games, crafts & fun await at the Kids Clubs! Make new friends, join themed adventures & special events. Parents relax knowing their little explorers are having a blast! Contact the representatives for Apollo, Exime or Grecos to find the kids club for your child.</p>
                <hr></hr>
            </div>
        </div>
    );
}

export default Events;
