import React from 'react';
import Header from './header.js'
import { useEffect } from 'react';
//import css
import './area.css'
//import images
import kayakImg from './../images/litleAcheron.webp'
import eventsImg from './../images/events_1.jpg'
import carImg from './../images/car.jpg'
const Area = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);



    return (
        <div>
            <Header/>
            <section id='header'>
            <h2 className='sectionTitles mx-3 mt-4'>Explore the area</h2>
            </section>
            <div className='d-flex flex-column col-12 py-4'>
                <h3 className='sectionTitles mx-4 '>Acheron River</h3>
                <div className='imgHolder mb-4'>
                    <img src={kayakImg} className='eventsImg'/>
                </div>
                <p className='myText'>
Discover the mythical and notorious Acheron river by kayak. Perfect for families and couples of all paddling abilities, with pick-up available you explore these legendary waters.  <a href='https://dragonfly-kayak.com'> View Website & Book</a> with Dragonfly kayak.</p>
                <hr></hr>
            </div>
            <div className='d-flex flex-column col-12 py-4'>
                <h3 className='sectionTitles mx-4 '>Preveza</h3>
                <div className='imgHolder mb-4'>
                    <img src={eventsImg} className='eventsImg'/>
                </div>
                <p className='myText'>Go with the bus to the coastal town of Preveza. Stroll through its historical centre and along the scenic waterfront promenade with an impressive marina. Preveza charms with its relaxed atmosphere and delicious cuisine.</p>
                <hr></hr>
            </div>
            <div className='d-flex flex-column col-12 py-4'>
                <h3 className='sectionTitles mx-4 '>Rent a car</h3>
                <div className='imgHolder mb-4'>
                    <img src={carImg} className='eventsImg'/>
                </div>
                <p className='myText'>Make the most of your Greek adventure and rent a car to discover the area. Epirus has many beautiful villages, historical sites, and natural wonders scattered throughout the region as well as a stunning coastline with secluded coves and beaches. Having a car makes these day trips convenient and allows you to pack in more experiences during your stay. Contact the reception to book your car!</p>
                <hr></hr>
            </div>
        </div>
    );
}

export default Area;
